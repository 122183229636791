import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import NotificationEllipsisView from "src/shared/notification/NotificationEllipsisView";
import RewardEvents from "src/shared/rewards/RewardEvents";
import RewardSupplierFormatter from "src/shared/RewardSupplierFormatter";
import ModalManager from "src/shared/ModalManager";
import pageTemplate from "src/shared/modals/reward-supplier-archive-modal-template.html?raw";

const RewardSupplierArchiveModalView = Backbone.View.extend({
    template: _.template(pageTemplate),

    events: {
        "click #js-delete": "confirmDelete",
    },

    initialize(attributes) {
        this.eventBus = attributes.eventBus;
        this.model.loadCampaigns().always(this.render.bind(this));
    },

    render(campaigns) {
        const coupon = this.model.toJSON();
        this.$el.html(
            this.template(
                _.extend(coupon, {
                    formattedValue: RewardSupplierFormatter.formatValue(
                        this.model
                    ),
                    campaigns,
                })
            )
        );
        this.$deleteButton = this.$("#js-delete");
        ModalManager.start(this.$el);
        NotificationEllipsisView.renderAll(this.$el);

        $(document.body).append(this.el);
    },

    confirmDelete(event) {
        event.preventDefault();
        const self = this;
        const { collection } = this.model;
        this.disableActionButtons(true);
        const urlValue = this.model.url();
        $.when(
            this.model.destroy({
                wait: true,
                url: urlValue.replace("/built", ""),
            })
        )
            .done(() => {
                if (collection) {
                    collection.fetch();
                }
                self.eventBus.trigger(RewardEvents.REWARD_REMOVED);
                self.disableActionButtons(false);
                self.close();
            })
            .fail((error) => {
                self.disableActionButtons(false);
                self.close();
            });
    },

    disableActionButtons(disabled) {
        this.$deleteButton.prop("disabled", disabled);
    },

    close() {
        ModalManager.closeModal();
    },
});

RewardSupplierArchiveModalView.show = function (model, eventBus) {
    return new RewardSupplierArchiveModalView({
        model,
        eventBus,
    });
};

export default RewardSupplierArchiveModalView;
