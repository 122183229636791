import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import NotificationFlashView from "src/shared/notification/NotificationFlashView";
import NotificationEllipsisView from "src/shared/notification/NotificationEllipsisView";
import stickyAlertMessageService from "src/shared/alert-messages/sticky-alert-message-service";
import RewardSupplierArchiveModalView from "src/shared/modals/RewardSupplierArchiveModalView";
import RewardEvents from "src/shared/rewards/RewardEvents";
import RewardSupplierFormatter from "src/shared/RewardSupplierFormatter";
import FormView from "src/shared/FormView";
import Strings from "src/shared/Strings";
import RadioGroupView from "src/shared/RadioGroupView";
import NumericInputMask from "src/shared/NumericInputMask";
import ToggleSectionView from "src/shared/ToggleSectionView";
import TooltipView from "src/shared/tooltip/TooltipView";
import tooltipConfigurations from "src/shared/custom-reward/tooltip-configurations.json";
import pageTemplate from "src/shared/custom-reward/custom-reward-modal-template.html?raw";
import AddComponentReferenceBlock from "src/shared/v-components/components/AddComponentReferenceBlock.vue";
import { createApp } from "vue";
import Session from "Session";
import { useAppPinia } from "src/app-pinia";

const CUSTOM_REWARD_TYPES = ["ACCOUNT_CREDIT", "LOYALTY_POINTS"];
const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;
const TYPES = {
    CASH_BACK: "CASH_BACK",
    FIXED: "FIXED",
};

const CustomRewardModalView = Backbone.View.extend(
    {
        template: _.template(pageTemplate),

        events: {
            "click #js-delete": "showConfirmDelete",
            "click #js-back": "back",
            "keyup .error": "clearError",
            "click .js-switch-option": "clearErrorsOnSwitch",
            "click .js-state-selector li": "toggleRewardTransition",
            "change .js-face-value-type-select": "handleFaceValueTypeChange",
            "change #js-auto-fulfillment": "handleAutoFulfillmentChange",
            "change #js-auto-fulfillment-disabled":
                "handleAutoFulfillmentChange",
        },

        attributes: {
            class: "action-view__positioner",
        },

        initialize(options) {
            this.eventBus = _.extend({}, Backbone.Events);
            if (this.model.id) {
                this.title = `Edit ${Strings.capitalize(
                    this.model.get("name")
                )}`;
                this.isNewCoupon = false;
            } else {
                this.title = this.model.get("isDuplicate")
                    ? `Duplicate ${Strings.capitalize(this.model.get("name"))}`
                    : "New Custom Reward";
                this.isNewCoupon = true;
            }
            this.mainEventAggregator = options.mainEventAggregator;
            this.model.on("sync", this.renderForm.bind(this));

            this.eventBus.on("set_component_id", (id) => {
                this.model.set("component_ids", id ? [id] : [], {
                    silent: true,
                });
            });
            this.render();
        },

        toggleRewardTransition(event) {
            event.preventDefault();
            const $target = $(event.currentTarget);
            $target.toggleClass("active");
        },

        render() {
            this.coupon = this.model.toJSON();
            this.$el.html(
                this.template({
                    title: this.title,
                    isLoading: true,
                })
            );
            NotificationFlashView.create(this.$(".js-loader"));
            this.model.fetch();
            return this;
        },

        renderForm() {
            const customRewardTypes = _.map(
                CUSTOM_REWARD_TYPES,
                (customRewardType) => ({
                    name: Strings.capitalize(customRewardType),
                    value: customRewardType,
                })
            );

            if (!this.isNewCoupon) {
                this.coupon.cash_back_percentage_formatted =
                    this.coupon.cash_back_percentage * 100;
                if (this.coupon.face_value_algorithm_type === TYPES.CASH_BACK) {
                    this.coupon.face_value = 0;
                } else {
                    this.coupon.cash_back_percentage = 0;
                    this.coupon.cash_back_percentage_formatted = 0;
                }
            }

            const data = _.extend(this.coupon, {
                title: this.title,
                isLoading: false,
                isNewCoupon: this.isNewCoupon,
                customRewardTypes,
                valueTypes: RewardSupplierFormatter.getPossibleValueTypes(),
                missing_fulfillment_alert_delay: millisecondsToDays(
                    this.coupon.missing_fulfillment_alert_delay_ms,
                    this.coupon.missing_fulfillment_alert_enabled
                ),
                missing_fulfillment_auto_fail_delay: millisecondsToDays(
                    this.coupon.missing_fulfillment_auto_fail_delay_ms,
                    this.coupon.missing_fulfillment_auto_fail_enabled
                ),
                limit_per_day: this.coupon.limit_per_day,
                limit_per_hour: this.coupon.limit_per_hour,
                Strings,
            });

            data.type = this.coupon.type || this.coupon.reward_supplier_type;
            data.isSuperUser = Session.getInstance().isSuperUser();

            this.$el.html(this.template(data));
            this.$submitButton = this.$("#js-submit");
            this.$deleteButton = this.$("#js-delete");
            this.$successMessage = this.$(".js-success-message");
            this.$errorMessage = this.$(".js-error-message");
            this.successMessageHtml = $("#js-success-message-template").text();
            this.$customRewardName = $("#js-custom-reward-name");
            this.$faceValue = $(".js-fixed input.js-required");
            this.$cashBackValue = $(".js-cash-back input.js-required");
            this.$autoFullfillment = $("#js-auto-fulfillment");
            this.$autoFullfillmentDisabled = $("#js-auto-fulfillment-disabled");
            this.formView = new FormView.Builder()
                .withRootElement(this.$("form"))
                .withModel(this.model)
                .withSubmitMethod(this.submit.bind(this))
                .withStickyAlertMessageService(stickyAlertMessageService)
                .render();

            TooltipView.renderAll(tooltipConfigurations);
            NotificationEllipsisView.renderAll(this.$el);
            NumericInputMask.renderAll(this.$(".js-masked-input"));
            RadioGroupView.create(this.$("#js-value-wrapper"), this.model);
            ToggleSectionView.create(this.$("#js-advanced-section-wrapper"));

            const addComponentReferenceBlock = createApp(
                AddComponentReferenceBlock,
                {
                    eventBus: this.eventBus,
                    component_ids: this.model.get("component_ids"),
                }
            );
            addComponentReferenceBlock
                .use(useAppPinia())
                .mount("#js-add-reference-block");
            this.initializeFaceValueType();
            this.initializeAutoFullfiled();
            return this;
        },

        initializeFaceValueType() {
            this.$(".js-face-value-type").text(
                Strings.capitalize(this.$(".js-face-value-type-select").val())
            );
        },

        initializeAutoFullfiled() {
            if (this.model.isNew()) {
                this.$autoFullfillment.prop("checked", true);
            } else {
                this.$autoFullfillment.prop(
                    "checked",
                    this.model.get("auto_fulfillment_enabled")
                );

                this.$autoFullfillmentDisabled.prop(
                    "checked",
                    !this.model.get("auto_fulfillment_enabled")
                );
            }
        },

        afterSave() {
            this.mainEventAggregator.trigger(
                RewardEvents.REWARD_SAVED,
                this.model
            );
            this.disableActionButtons(false);
        },

        getStates: function (elementSelector) {
            return this.$(elementSelector)
                .find(".active")
                .map((i, element) => {
                    return $(element).data("value");
                })
                .get();
        },

        submit() {
            this.disableActionButtons(true);
            const urlValue = this.model.url();
            const stateTransitions = {
                EARNED: this.getStates(".js-earn-states-selector") || [],
                FULFILLED:
                    this.getStates(".js-fulfilled-states-selector") || [],
                SENT: this.getStates(".js-sent-states-selector") || [],
                REDEEMED: this.getStates(".js-redeemed-states-selector") || [],
                FAILED: this.getStates(".js-failed-states-selector") || [],
            };
            $.when(
                this.model.save(
                    {
                        state_transitions: stateTransitions,
                    },
                    { url: urlValue.replace("/built", "") }
                )
            )
                .done(this.afterSave.bind(this))
                .fail(this.disableActionButtons.bind(this, false));
        },

        disableActionButtons(disabled) {
            this.$submitButton.prop("disabled", disabled);
            this.$deleteButton.prop("disabled", disabled);
        },

        showConfirmDelete() {
            RewardSupplierArchiveModalView.show(
                this.model,
                this.mainEventAggregator
            );
        },

        handleFaceValueTypeChange(event) {
            const $target = $(event.currentTarget);
            const value = $target.val();
            this.model.set("face_value_type", value);
            this.$(".js-face-value-type").text(Strings.capitalize(value));
        },

        handleAutoFulfillmentChange(event) {
            event.preventDefault();
            const $target = $(event.currentTarget);
            const isChecked = $target.prop("checked");
            if ($target.attr("name") === "auto_fulfillment_enabled") {
                this.$autoFullfillmentDisabled.prop("checked", !isChecked);
            } else {
                this.$autoFullfillment.prop("checked", !isChecked);
            }
        },

        showStickyAlertMessage(message) {
            stickyAlertMessageService
                .buildAlertMessage()
                .withMessage(message)
                .render();
        },

        showError(error) {
            this.collectionReload();
            this.disableActionButtons(false);
            this.$errorMessage.text(error.responseJSON.message).show();
        },

        clearError(event) {
            this.formView.removeErrorStyling($(event.currentTarget));
        },

        clearErrorsOnSwitch() {
            if (
                this.model.toJSON().face_value_algorithm_type ===
                TYPES.CASH_BACK
            ) {
                this.formView.removeErrorStyling($(".js-fixed .error"));
            } else {
                this.formView.removeErrorStyling($(".js-cash-back .error"));
            }
        },

        back() {
            this.mainEventAggregator.trigger(RewardEvents.BACK);
        },
    },
    {
        create(model, mainEventAggregator) {
            return new CustomRewardModalView({
                model,
                mainEventAggregator,
            });
        },
    }
);

function millisecondsToDays(milliseconds, enabled) {
    if (!enabled) {
        return null;
    }

    return milliseconds / MILLISECONDS_IN_DAY;
}

export default CustomRewardModalView;
