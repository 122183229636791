import Backbone from "backbone";

const AvailableSalesforceCouponPoolModel = Backbone.Model.extend({
    urlRoot:
        "/api/v2/reward-suppliers/salesforce-coupons/list-available-coupon-pools",
});

export default Backbone.Collection.extend({
    url: "/api/v2/reward-suppliers/salesforce-coupons/list-available-coupon-pools",

    model: AvailableSalesforceCouponPoolModel,

    sync(method, collection, options) {
        if (method !== "read") {
            throw new Error(`Unsupported sync operation ${method}`);
        }
        return Backbone.sync.call(this, method, collection, options);
    },
});
