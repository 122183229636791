import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Numbers from "src/shared/Numbers";

const NumericInputMask = Backbone.View.extend({
    initialize(options) {
        this.$inputs = options.$inputs;
    },

    render() {
        if (this.$inputs.length === 0) {
            return;
        }

        this.$inputs.each(function () {
            const $formattedInput = $(this);
            const name = $formattedInput.attr("name");
            const $input = $("<input />");

            $input.attr("name", name);
            $input.attr("type", "hidden");
            $formattedInput.removeAttr("name");
            $formattedInput.after($input);
            setInputValue($formattedInput, $input);

            $formattedInput.on(
                "blur",
                _.partial(setInputValue, $formattedInput, $input)
            );
            $formattedInput.on(
                "change",
                _.partial(setInputValue, $formattedInput, $input)
            );
        });
    },
});

function setInputValue($formattedInput, $input) {
    const value = $formattedInput.val().replace(/,/g, "");
    const formattedValue = Numbers.humanizeInputValue(value);

    $input.val(value);
    $formattedInput.val(!isNaN(parseFloat(value)) ? formattedValue : "");
}

NumericInputMask.renderAll = function ($el) {
    return new NumericInputMask({
        $inputs: $el,
    }).render();
};

export default NumericInputMask;
