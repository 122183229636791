import $ from "jquery";
import _ from "underscore";

const ALERT_TYPES = {
    WARNING: "warning",
    ERROR: "error",
};

function FocusOnErrorFieldService() {
    function focus(elements, type) {
        const _type = type || ALERT_TYPES.ERROR;
        const errorElement = _.chain(elements)
            .filter(
                (element) => $(element).is("input") || $(element).is("textarea")
            )
            .first()
            .value();
        $(errorElement).addClass(_type).focus();
    }

    function getTypes() {
        return ALERT_TYPES;
    }

    this.focus = focus;
    this.getTypes = getTypes;
}

export default new FocusOnErrorFieldService();
