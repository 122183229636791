import Backbone from "backbone";
import _ from "underscore";
import $ from "jquery";
import RewardStateSummaryCollection from "src/account-rewards/RewardStateSummaryCollection";
import CampaignCollection from "src/account-rewards/CampaignCollection";
import notificationService from "src/shared/notification/notification-service";
import SimpleModel from "src/shared/SimpleModel";
import Strings from "src/shared/Strings";

const REWARD_SUPPLIER_TYPES = {
    TANGO_V2: "TANGO_V2",
    CUSTOM_REWARD: "CUSTOM_REWARD",
    MANUAL_COUPON: "MANUAL_COUPON",
    PAYPAL_PAYOUTS: "PAYPAL_PAYOUTS",
    SALESFORCE_COUPON: "SALESFORCE_COUPON",
};

const CouponStatsModel = Backbone.Model.extend({
    initialize(attributes, options) {
        this.path = options.path;
    },

    url() {
        return `/api/v2/reward-suppliers/${this.path}/${this.get("id")}/stats`;
    },

    parse(model) {
        const remainedCoupons = model.number_of_available_coupons;
        const usedCoupons = model.number_of_issued_coupons;
        _.extend(model, {
            remainedCoupons,
            usedCoupons,
            totalCoupons: remainedCoupons + usedCoupons,
        });
    },
});

const RewardSupplierModel = Backbone.Model.extend(
    {
        urlRoot: "/api/v6/reward-suppliers",

        loadStats() {
            const stats = initializeStats(this);
            if (stats) {
                return stats.fetch();
            }

            const deferred = $.Deferred();
            return deferred.reject();
        },

        loadCampaigns() {
            const campaignCollection = new CampaignCollection(
                {},
                { rewardSupplierId: this.id }
            );
            return campaignCollection.fetch();
        },

        parseRequest() {},

        sync(method, model, options) {
            this.method = method;
            this.parseRequest.call(this, method, model, options);
            return Backbone.sync.call(this, method, model, options);
        },

        destroy(options) {
            const deferred = $.Deferred();
            const self = this;
            $.when(Backbone.Model.prototype.destroy.call(this, options))
                .done(() => {
                    notifySuccess(self, "archived");
                    deferred.resolve();
                })
                .fail(() => {
                    deferred.reject();
                });

            return deferred;
        },

        parse(data) {
            let action;
            switch (this.method) {
                case "create":
                    action = "created";
                    break;

                case "update":
                    action = "updated";
                    break;

                default:
                    return data;
            }

            notifySuccess(this, action);
            if (this.collection) {
                this.collection.fetch();
            }
            return data;
        },
    },
    {
        REWARD_SUPPLIER_TYPES,
    }
);

function notifySuccess(supplier, action) {
    notificationService.success(
        `${Strings.capitalize(supplier.get("name"))} has been ${action}.`
    );
}

function initializeStats(supplier) {
    if (supplier.id) {
        switch (supplier.get("reward_supplier_type") || supplier.type) {
            case REWARD_SUPPLIER_TYPES.MANUAL_COUPON:
                return new CouponStatsModel(supplier, {
                    path: "manual-coupons",
                });

            case REWARD_SUPPLIER_TYPES.SALESFORCE_COUPON:
                return new CouponStatsModel(supplier, {
                    path: "salesforce-coupons",
                });

            case REWARD_SUPPLIER_TYPES.TANGO_V2:
            case REWARD_SUPPLIER_TYPES.CUSTOM_REWARD:
                var stateModel = new SimpleModel({
                    period: "MONTH",
                    periodCount: 3,
                    reward_supplier_id: supplier.id,
                });
                return new RewardStateSummaryCollection(
                    {},
                    {
                        stateModel,
                        parseData(models) {
                            const initialData = {
                                earned: 0,
                                fulfilled: 0,
                                sent: 0,
                                canceled: 0,
                                redeemed: 0,
                                failed: 0,
                                revoked: 0,
                            };

                            const groupedData = models.reduce(
                                (accumulator, model) => {
                                    _.each(_.keys(initialData), (key) => {
                                        accumulator[key] += model[key];
                                    });

                                    return accumulator;
                                },
                                initialData
                            );

                            models.groupedData = groupedData;

                            return models;
                        },
                    }
                );
        }
    }
}

export default RewardSupplierModel;
