import Backbone from "backbone";
import _ from "underscore";

const SalesforceSettingsModel = Backbone.Model.extend({
    defaults: {
        name: null,
        base_uri: null,
        site_id: null,
        user_name: null,
        password: null,
        disabled: false,
    },

    urlRoot: "/api/v2/settings/salesforce",

    sync(method, model, options) {
        const omitAttributes = ["enabled", "id"];

        if (method === "update") {
            this.attributes.disabled = !this.attributes.enabled;
            if (this.attributes.password === "--Obfuscated password--") {
                omitAttributes.push("password");
            }
            options.attrs = _.omit(this.attributes, omitAttributes);
        } else if (method === "create") {
            options.attrs = _.omit(this.attributes, "disabled");
        }

        return Backbone.sync.call(this, method, model, options);
    },
});

export default SalesforceSettingsModel;
