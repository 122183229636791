import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import ConfirmationModalView from "src/shared/modals/ConfirmationModalView";
import CouponUploadModel from "src/account-rewards/coupons/CouponUploadModel";
import confirmForceUploadHTML from "src/account-rewards/workflow/reward-supplier-manual-coupon-confirm-force-upload.html?raw";
import couponIcon from "assets/account-rewards/rewards_icon2@2x.png";

const STATUSES = {
    FAILED: "FAILED",
};
const DUPLICATION_ERROR_CODES = [
    "duplicate_coupon_code_in_list",
    "existing_coupon_code",
];
const EXPIRED_COUPONS_ERROR_MESSAGE =
    "One or more coupons in your set has either already expired or is expiring soon. " +
    "Please remove the expired or expiring coupons, shown below, and upload your coupon set again.";
const RESTRICTED_CHARACTERS_ERROR_MESSAGE =
    "Coupon codes typically only contain alphanumeric characters, dashes, and underscore";
const hasResponseJSON = (response) => _.has(response, "responseJSON");
const hasDuplicates = (code) => _.contains(DUPLICATION_ERROR_CODES, code);
const hasExpiredCoupons = (response) =>
    EXPIRED_COUPONS_ERROR_MESSAGE.includes(response.result_message);
const hasRestrictedCharacters = (response) =>
    response.result_message.includes(RESTRICTED_CHARACTERS_ERROR_MESSAGE);
const parseResponse = (response) => {
    let responseData = {};
    if (hasResponseJSON(response)) {
        responseData.result_message = response.responseJSON.message;
        responseData.coupons = [response.responseJSON.parameters.coupon_code];
        responseData.number_of_coupons_to_upload = responseData.coupons.length;
        responseData.error_code = response.responseJSON.code;
    } else {
        responseData = response;
    }
    return responseData;
};
const parseNeededParameter = (response) => {
    let parameter;
    if (hasExpiredCoupons(response)) {
        parameter = "allow_expired";
    } else if (hasRestrictedCharacters(response)) {
        parameter = "allow_restricted_characters";
    } else {
        parameter = "discard_duplicated";
    }
    return parameter;
};
const MAX_COUPONS_TO_DISPLAY = 5;
const confirmForceUploadTemplate = _.template(confirmForceUploadHTML);

export default function (model, file, filterModel = new Backbone.Model()) {
    const deferred = $.Deferred();
    function confirmForceUpload(response) {
        const data = parseResponse(response);
        const modalMessage = confirmForceUploadTemplate(
            _.extend(
                {
                    MAX_COUPONS_TO_DISPLAY,
                    couponIcon,
                },
                data
            )
        );
        const modal = ConfirmationModalView.create(
            "Confirm Coupon Upload",
            modalMessage,
            "Upload Anyway",
            true,
            "Cancel Upload"
        );
        modal.on(ConfirmationModalView.EVENTS.CONFIRMED, () => {
            const parameter = parseNeededParameter(data);
            uploadCoupons(parameter);
            modal.close();
        });
        modal.on(ConfirmationModalView.EVENTS.CANCELED, () => {
            deferred.reject({
                responseJSON: {
                    message: "Upload canceled",
                },
            });
        });
    }

    function onCouponsUpload(response, error) {
        const data = hasResponseJSON(response)
            ? response.responseJSON
            : response;
        if (response.status === STATUSES.FAILED || hasDuplicates(data.code)) {
            confirmForceUpload(response);
            return;
        }
        if (error === "error") {
            deferred.reject();
        } else {
            deferred.resolve();
        }
    }

    function uploadCoupons(parameter) {
        if (parameter) {
            filterModel.set(parameter, true);
        }
        const rewardSupplierModel = CouponUploadModel.create(
            model,
            filterModel
        );
        $.when(rewardSupplierModel.save({}, { file })).always(onCouponsUpload);
        return deferred;
    }

    this.upload = uploadCoupons;
}
