import _ from "underscore";
import Numbers from "src/shared/Numbers";

const VALUE_TYPES = {
    USD: "$",
    PERCENT_OFF: "%",
    POINTS: "points",
    AUD: "Au$",
    BRL: "R$",
    CAD: "Can$",
    CNY: "RMB￥",
    EUR: "€",
    GBP: "£",
    INR: decodeURIComponent("\u20B9"),
    JPY: "JP¥",
    KRW: decodeURIComponent("\u20A9"),
    MONTH: "Months",
    MXN: "Mex$",
    NZD: "NZ$",
    TRY: decodeURIComponent("\u20BA"),
    TWD: "NT$",
    HKD: "HK$",
};

export default {
    getPossibleValueTypes() {
        return Object.keys(VALUE_TYPES);
    },
    formatValue(rewardSupplierModel, specialValue) {
        if (!rewardSupplierModel) {
            return null;
        }

        const rewardSupplier = rewardSupplierModel.toJSON();
        if (rewardSupplier.face_value_algorithm_type === "CASH_BACK") {
            const percentage = rewardSupplier.cash_back_percentage;
            return percentage
                ? `${parseFloat(parseFloat(percentage * 100).toFixed(2))}%`
                : "";
        }

        const value = specialValue || rewardSupplier.face_value;
        const valueType = rewardSupplier.face_value_type;
        const type = VALUE_TYPES[valueType];

        switch (valueType) {
            case "POINTS":
                return `${Numbers.humanize(value)} ${type}`;

            case "PERCENT_OFF":
                return Numbers.humanize(value) + type;

            default:
                return type + Numbers.humanize(value);
        }
    },
};
