import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Strings from "src/shared/Strings";
import template from "src/shared/upload-file-modal/inner-upload-template.html?raw";
import inlineUploadTemplate from "src/shared/upload-file-modal/inline-upload-template.html?raw";

const BYTES_IN_KILOBYTE = 1024;
const EVENTS = {
    FILE_UPLOADED: "FILE_UPLOADED",
};

const InnerUploadView = Backbone.View.extend(
    {
        template: _.template(template),

        events: {
            "click #js-choose-file": "chooseFile",
            "change #js-file": "changeFileName",
            "dragover #js-drop-area": "highlightDropArea",
            "dragleave #js-drop-area": "unHighlightDropArea",
            "drop #js-drop-area": "handleDrop",
        },

        initialize(attributes) {
            this.message = attributes.message;
            this.$submitButton = attributes.submitButton;
            this.isInline = attributes.isInline;
            this.template = this.isInline
                ? _.template(inlineUploadTemplate)
                : _.template(template);
        },

        render() {
            this.$el.html(
                this.template({
                    message: this.message,
                    Strings,
                })
            );
            this.$fileField = this.$("#js-file");
            this.$chooseFile = this.$("#js-choose-file");
            this.$fileName = this.$("#js-file-name");

            return this;
        },

        chooseFile(event) {
            event.preventDefault();
            this.$fileField.click();
        },

        changeFileName() {
            const file = this.$fileField[0].files[0] || this.file;
            if (!file) {
                return;
            }

            const size =
                file.size < BYTES_IN_KILOBYTE
                    ? `${file.size} bytes`
                    : `${Math.round(file.size / BYTES_IN_KILOBYTE)}kb`;
            if (this.isInline) {
                this.$fileName.parent().removeClass("hide");
            }
            this.$submitButton.prop("disabled", false);
            this.$fileName.html(`File Name: ${file.name} (${size})`);
            this.file = file;
            this.trigger(EVENTS.FILE_UPLOADED, this.file);
        },

        highlightDropArea(event) {
            event.preventDefault();
            event.stopPropagation();
            $(event.currentTarget).addClass("highlight");
            if (event.originalEvent.dataTransfer) {
                event.originalEvent.dataTransfer.dropEffect = "copy";
            }
        },

        unHighlightDropArea(event) {
            event.preventDefault();
            event.stopPropagation();
            $(event.currentTarget).removeClass("highlight");
        },

        handleDrop(event) {
            this.unHighlightDropArea(event);
            this.file =
                event.originalEvent.dataTransfer &&
                event.originalEvent.dataTransfer.files[0];
            this.changeFileName();
        },

        getFile() {
            return this.file;
        },
    },
    {
        EVENTS,
    }
);

InnerUploadView.create = function (message, submitButton, isInline) {
    return new InnerUploadView({
        message,
        submitButton,
        isInline,
    }).render();
};

export default InnerUploadView;
