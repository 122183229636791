import Backbone from "backbone";
import _ from "underscore";
import Uri from "jsuri";

const CouponUploadModel = Backbone.Model.extend({
    isNew() {
        return true;
    },

    url() {
        const uri = new Uri(
            `/api/v2/reward-suppliers/manual-coupons/${this.rewardSupplierId}/coupons`
        );
        const filter = this.filterModel.toJSON();
        _.each(_.pairs(filter), (pair) => {
            if (pair[1]) {
                uri.addQueryParam(pair[0], pair[1]);
            }
        });
        return uri.toString();
    },

    initialize(attributes, options) {
        this.rewardSupplierId = options.rewardSupplierId;
        this.filterModel = options.filterModel;
    },

    sync(method, model, options) {
        const formData = new FormData();
        if (method === "create" || method === "update") {
            formData.append("file", options.file, options.file.name);
            return Backbone.ajax(this.url(), {
                type: "POST",
                processData: false,
                contentType: false,
                data: formData,
            });
        }
        return Backbone.sync.call(this, method, model, options);
    },
});

CouponUploadModel.create = function (rewardSupplierModel, filterModel) {
    return new CouponUploadModel(
        {},
        {
            rewardSupplierId: rewardSupplierModel.get("id"),
            filterModel,
        }
    );
};

export default CouponUploadModel;
