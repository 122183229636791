import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import CouponsFactory from "src/account-rewards/coupons/CouponsFactory";
import RewardSupplierArchiveModalView from "src/shared/modals/RewardSupplierArchiveModalView";
import RewardEvents from "src/shared/rewards/RewardEvents";
import NotificationEllipsisView from "src/shared/notification/NotificationEllipsisView";
import notificationService from "src/shared/notification/notification-service";
import stickyAlertMessageService from "src/shared/alert-messages/sticky-alert-message-service";
import RewardSupplierFormatter from "src/shared/RewardSupplierFormatter";
import FormView from "src/shared/FormView";
import Strings from "src/shared/Strings";
import CalendarView from "src/shared/calendar/CalendarView";
import NumericInputMask from "src/shared/NumericInputMask";
import ToggleSectionView from "src/shared/ToggleSectionView";
import RewardSupplierManualUploadCouponView from "src/account-rewards/workflow/RewardSupplierManualUploadCouponView";
import TooltipView from "src/shared/tooltip/TooltipView";
import tooltipConfigurations from "src/shared/coupon/tooltip-configurations.json";
import pageTemplate from "src/account-rewards/workflow/reward-supplier-manual-coupon-manager-view-template.html?raw";

const DEFAULT_COUPON_COUNT_WARN_LIMIT = 1500;

const RewardSupplierManualCouponManagerView = Backbone.View.extend(
    {
        template: _.template(pageTemplate),

        events: {
            "click #js-back": "back",
            "click #js-delete": "showConfirmDelete",
            "click #js-coupons-upload": "chooseCouponsFile",
            "change #js-file": "choosedFile",
            "change .js-date-field": "changeInput",
            "keyup .error": "clearError",
        },

        attributes: {
            class: "action-view__positioner",
        },

        initialize(parameters) {
            this.mainEventAggregator = parameters.mainEventAggregator;
            this.model.on("sync", this.render.bind(this));
            this.coupon = this.model.toJSON();
            this.isNewCoupon = this.model.isNew();
            this.render();
        },

        render() {
            const coupon = this.model.toJSON();
            let title;
            if (coupon.id) {
                title = `Edit ${Strings.capitalize(coupon.name)}`;
                this.isNewCoupon = false;
            } else {
                title = coupon.isDuplicate
                    ? `Duplicate ${Strings.capitalize(coupon.name)}`
                    : "New Coupon";
                this.isNewCoupon = true;
            }
            this.$el.html(
                this.template(
                    _.extend(coupon, {
                        title,
                        isNewCoupon: this.isNewCoupon,
                        valueTypes:
                            RewardSupplierFormatter.getPossibleValueTypes(),
                        DEFAULT_COUPON_COUNT_WARN_LIMIT,
                    })
                )
            );
            this.uploadView = RewardSupplierManualUploadCouponView.create(
                "Drag and drop a .csv, .psv or .json file",
                this.model
            );
            this.$(".js-upload-panel").append(this.uploadView.el);
            this.$fileField = this.$el.find("#js-file");
            this.$fileName = this.$el.find("#js-file-name");
            this.$submitButton = this.$("#js-submit");
            this.$deleteButton = this.$("#js-delete");
            this.$errorMessage = this.$(".js-error-message");
            this.$couponCountLimit = this.$("#js-coupon-limit");
            this.$expirationDefault = this.$el.find(".js-expiration");
            this.$uploadPanel = this.$el.find(".js-upload-panel");
            this.$defaultExpirationDate = this.$el.find(
                ".js-expiration-date-optional"
            );
            this.$defaultExpirationCouponDate = this.$(
                "#js-expiration-date-default"
            );
            this.defaultCouponExpiryDate = this.model.get(
                "default_coupon_expiry_date"
            );
            this.defaultExpiryDate = this.model.get("default_expiry_date");
            this.formView = new FormView.Builder()
                .withRootElement(this.$("form"))
                .withModel(this.model)
                .withSubmitMethod(this.submit.bind(this))
                .withStickyAlertMessageService(stickyAlertMessageService)
                .render();
            TooltipView.renderAll(this.$el, tooltipConfigurations);
            NotificationEllipsisView.renderAll(this.$el);
            NumericInputMask.renderAll(this.$(".js-masked-input"));
            ToggleSectionView.create(this.$("#js-advanced-section-wrapper"));
            this.calendarDefaultView = CalendarView.create(
                this.$expirationDefault
            )
                .withName("default_coupon_expiry_date")
                .withModel(this.model)
                .withAlign("")
                .withValue(this.model.get("default_coupon_expiry_date"))
                .build();
            this.calendarDefaultView.on(CalendarView.EVENTS.CHANGED, () => {
                this.$defaultExpirationDate
                    .val(this.$defaultExpirationCouponDate.val())
                    .text(this.$defaultExpirationCouponDate.text());
                this.model.unset("default_expiry_date");
            });
            this.calendarView = CalendarView.create(this.$uploadPanel)
                .withName("default_expiry_date")
                .withModel(this.model)
                .withAlign("")
                .withValue()
                .build();
            return this;
        },

        onSave() {
            this.mainEventAggregator.trigger(
                RewardEvents.REWARD_SAVED,
                this.model
            );
            if (this.isNewCoupon) {
                window.location.href = `/account-rewards/coupons/view#${this.model.id}`;
            }
        },

        onSaveWithFile() {
            notificationService.success(
                `Your coupons have been uploaded to ${Strings.capitalize(
                    this.model.get("name")
                )}.`
            );
            this.onSave();
        },

        submit() {
            this.disableActionButtons(true);

            if (!this.isNewCoupon && !this.$couponCountLimit.val()) {
                this.formView.applyErrorStyling(
                    this.$couponCountLimit,
                    "Coupon count warn limit must be defined"
                );
                this.disableActionButtons(false);
                return;
            }
            this.model.unset("default_expiry_date");
            $.when(this.model.save())
                .done(() => {
                    this.model.fetch().done(() => {
                        this.uploadCoupons();
                    });
                })
                .fail(this.disableActionButtons.bind(this, false));
        },

        getUploadedFile() {
            return this._uploadedFile;
        },

        setUploadedFile(file) {
            this._uploadedFile = file;
        },

        uploadCoupons() {
            const filterModel = new Backbone.Model();
            const file = this.getUploadedFile();
            if (!file) {
                this.onSave();
                return;
            }
            filterModel.set({
                default_expiry_date: this.$("[name=default_expiry_date]").val(),
            });
            const couponsFactory = new CouponsFactory(
                this.model,
                file,
                filterModel
            );
            $.when(couponsFactory.upload())
                .done(this.onSaveWithFile.bind(this))
                .fail(this.showError.bind(this));
        },

        disableActionButtons(disabled) {
            this.$submitButton.prop("disabled", disabled);
            this.$deleteButton.prop("disabled", disabled);
        },

        showConfirmDelete() {
            RewardSupplierArchiveModalView.show(
                this.model,
                this.mainEventAggregator
            );
        },

        chooseCouponsFile(event) {
            event.preventDefault();
            this.$fileField.click();
        },

        choosedFile() {
            const file = this.$fileField[0].files[0];
            this.setUploadedFile(file);
            this.$fileName.val(file && file.name);
        },

        showError(response) {
            this.disableActionButtons(false);
            stickyAlertMessageService
                .buildAlertMessage()
                .withMessage(response.responseJSON.message)
                .render();
        },

        clearError(event) {
            const $target = $(event.currentTarget);
            this.formView.removeErrorStyling($target);
        },

        back() {
            this.mainEventAggregator.trigger(RewardEvents.BACK);
        },
    },
    {
        create(model, mainEventAggregator) {
            return new RewardSupplierManualCouponManagerView({
                model,
                mainEventAggregator,
            });
        },
    }
);

export default RewardSupplierManualCouponManagerView;
