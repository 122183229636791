import _ from "underscore";
import Backbone from "backbone";
import template from "src/shared/alert-messages/sticky-alert-message-wide-template.html?raw";

const ANIMATION_TIME_IN_MILLISECONDS = 400;
const CLOSE_MODAL_DELAY = 5000;
const EVENTS = {
    ACTION: "ACTION",
};

const StickyAlertMessageView = Backbone.View.extend(
    {
        template: _.template(template),

        tagName: "div",

        attributes: {
            class: "alert-message sticky-wide",
        },

        events: {
            "click .js-close": "close",
            "click .js-action": "triggerAction",
        },

        initialize(parameters) {
            this.message = parameters.message;
            this.type = parameters.type;
        },

        render() {
            const type = this.type || "error";
            const alertClass = `${type} js-${type}-message`;
            this.$el.addClass(alertClass);
            this.$el
                .html(
                    this.template({
                        message: this.message,
                        type: this.type,
                    })
                )
                .show();

            setTimeout(() => {
                this.close();
            }, CLOSE_MODAL_DELAY);

            return this;
        },

        triggerAction(event) {
            event.preventDefault();
            this.trigger(EVENTS.ACTION);
            this.close();
        },

        close(event) {
            if (event) {
                event.preventDefault();
            }
            this.$el.slideUp(ANIMATION_TIME_IN_MILLISECONDS, function () {
                this.remove();
            });
        },
    },
    {
        EVENTS,
    }
);

export default StickyAlertMessageView;
