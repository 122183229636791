import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import NotificationEllipsisView from "src/shared/notification/NotificationEllipsisView";
import NotificationFlashView from "src/shared/notification/NotificationFlashView";
import stickyAlertMessageService from "src/shared/alert-messages/sticky-alert-message-service";
import RewardSupplierArchiveModalView from "src/shared/modals/RewardSupplierArchiveModalView";
import RewardEvents from "src/shared/rewards/RewardEvents";
import SalesforceSettingsCollection from "src/settings/SalesforceSettingsCollection";
import AvailableSalesforceCouponPoolCollection from "src/shared/salesforce-coupon/AvailableSalesforceCouponPoolCollection";
import RewardSupplierFormatter from "src/shared/RewardSupplierFormatter";
import FormView from "src/shared/FormView";
import Strings from "src/shared/Strings";
import NumericInputMask from "src/shared/NumericInputMask";
import ToggleSectionView from "src/shared/ToggleSectionView";
import TooltipView from "src/shared/tooltip/TooltipView";
import tooltipConfigurations from "src/shared/salesforce-coupon/tooltip-configurations.json";
import pageTemplate from "src/shared/salesforce-coupon/salesforce-coupon-modal-template.html?raw";
import couponPoolListTemplate from "src/shared/salesforce-coupon/salesforce-coupon-pool-list-template.html?raw";
import salesforceSettingsListTemplate from "src/shared/salesforce-coupon/saleforce-settings-list-template.html?raw";
import queryStringService from "../query-string-service";

const SalesforceCouponModalView = Backbone.View.extend({
    template: _.template(pageTemplate),

    events: {
        "click #js-refill": "refill",
        "click #js-back": "back",
        "click #js-delete": "showConfirmDelete",
        "click .js-go-to-settings": "goToSettings",
        "change [name='coupon_pool_id']": "changeCouponPool",
        "change #salesforce_settings_id": "filterCouponPool",
    },

    initialize(options) {
        this.mainEventAggregator = options.mainEventAggregator;
        this.model.on("sync", this.render.bind(this));
        this.render();
    },

    render() {
        this.renderModal();
        this.renderModalContent();
        this.$submitButton = this.$("[type='submit']");
        this.$deleteButton = this.$("#js-delete");
        return this;
    },

    onSave() {
        const self = this;

        if (this.isNewCoupon) {
            $.when(this.model.refill())
                .done(() => {
                    self.afterSave();
                })
                .fail(() => {
                    self.$warningMessage.html(self.warningMessageHtml).show();
                    self.afterSave();
                });
        } else {
            this.afterSave();
        }
    },

    afterSave() {
        this.mainEventAggregator.trigger(RewardEvents.REWARD_SAVED, this.model);
    },

    submit() {
        this.disableActionButtons(true);
        $.when(this.model.save())
            .done(this.onSave.bind(this))
            .fail(this.disableActionButtons.bind(this, false));
    },

    refill() {
        const self = this;
        this.disableActionButtons(true);
        $.when(this.model.refill())
            .done(() => {
                self.afterSave();
            })
            .fail(() => {
                self.$warningMessage.html(self.warningRefillMessageHtml).show();
                self.afterSave();
            });
    },

    filterCouponPool(event) {
        const value = $(event.target).val();
        this.renderCouponsDropdown(value);
    },

    renderModal() {
        const coupon = this.model.toJSON();
        let title;
        if (coupon.id) {
            title = `Edit ${Strings.capitalize(coupon.name)}`;
            this.isNewCoupon = false;
        } else {
            title = coupon.isDuplicate
                ? `Duplicate ${Strings.capitalize(coupon.name)}`
                : "New Salesforce Commerce Cloud Coupon";
            this.isNewCoupon = true;
        }

        this.$el.html(
            this.template(
                _.extend(coupon, {
                    title,
                    isNewCoupon: this.isNewCoupon,
                    valueTypes: RewardSupplierFormatter.getPossibleValueTypes(),
                })
            )
        );

        TooltipView.renderAll(tooltipConfigurations);
        NotificationFlashView.create(this.$(".js-page-loader"));
    },

    renderModalContent() {
        const self = this;
        this.salesforceSettingsCollection = new SalesforceSettingsCollection();
        this.$content = this.$("#js-salesforce-coupon-modal-content");
        this.$header = this.$("#js-salesforce-coupon-modal-header");
        this.$couponPoolDropdown = this.$("[name='coupon_pool_id']");
        this.$saleforceDropdown = this.$("#salesforce_settings_id");
        $.when(this.salesforceSettingsCollection.fetch())
            .done(() => {
                this.enabledSettings = _.filter(
                    this.salesforceSettingsCollection.toJSON(),
                    (model) => !model.disabled
                );
                if (_.isEmpty(this.enabledSettings)) {
                    this.showNoSettingsContent();
                } else if (
                    _.isEmpty(this.salesforceSettingsCollection.models)
                ) {
                    this.showDisabledSettingsContent();
                } else {
                    this.showFormContent();
                }
            })
            .fail(this.showNoSettingsContent.bind(this));
    },

    changeCouponPool(event) {
        const value = $(event.target).val();
        this.$name.val(value);
    },

    showDisabledSettingsContent() {
        showModalContent(
            this,
            "#js-salesforce-coupon-settings-disabled-template"
        );
    },

    showNoSettingsContent() {
        showModalContent(
            this,
            "#js-salesforce-coupon-settings-not-set-up-template",
            "Set up Salesforce Commerce Cloud"
        );
    },

    renderCouponsDropdown(settingsId) {
        const couponPools = _.where(this.couponPoolCollection.toJSON(), {
            settings_id: settingsId,
        });
        this.$couponPoolDropdown.html(
            _.template(couponPoolListTemplate)({
                couponPools,
                currentCouponPool: this.model.get("coupon_pool_id"),
            })
        );
    },

    showFormContent() {
        this.couponPoolCollection =
            new AvailableSalesforceCouponPoolCollection();
        $.when(this.couponPoolCollection.fetch())
            .done(() => {
                showModalContent(
                    this,
                    "#js-salesforce-coupon-content-template"
                );

                this.$couponPoolDropdown = this.$("[name='coupon_pool_id']");
                this.$saleforceDropdown = this.$("#salesforce_settings_id");

                this.$saleforceDropdown.html(
                    _.template(salesforceSettingsListTemplate)({
                        salesforceSettings: this.enabledSettings,
                        currentSalesforceSettings: this.model.isNew()
                            ? queryStringService.getParameter("account_id")
                            : this.model.get("settings_id"),
                    })
                );

                this.renderCouponsDropdown();
                this.$name = this.$("[name='name']");
                this.$submitButton = this.$("[type='submit']");
                this.$warningMessage = this.$(".js-warning-message");

                new FormView.Builder()
                    .withRootElement(this.$el.find("form"))
                    .withSubmitMethod(this.submit.bind(this))
                    .withModel(this.model)
                    .withStickyAlertMessageService(stickyAlertMessageService)
                    .render();

                TooltipView.renderAll(tooltipConfigurations);
                NotificationEllipsisView.renderAll(this.$el);
                NumericInputMask.renderAll(this.$(".js-masked-input"));
                ToggleSectionView.create(
                    this.$("#js-advanced-section-wrapper")
                );
            })
            .fail((response) => {
                this.disableActionButtons(true);
                this.showErrorMessage(response.responseJSON.message);
            });
    },
    showErrorMessage(message) {
        stickyAlertMessageService
            .buildAlertMessage()
            .withMessage(message)
            .render();
        this.$content.text(message);
    },

    disableActionButtons(disabled) {
        this.$submitButton.prop("disabled", disabled);
        this.$deleteButton.prop("disabled", disabled);
    },

    showConfirmDelete() {
        RewardSupplierArchiveModalView.show(
            this.model,
            this.mainEventAggregator
        );
    },

    goToSettings() {
        document.location = "/partners#/salesforce";
    },

    back() {
        queryStringService.removeParameter("account_id");
        this.mainEventAggregator.trigger(RewardEvents.BACK);
    },
});

SalesforceCouponModalView.create = function (model, mainEventAggregator) {
    return new SalesforceCouponModalView({
        model,
        mainEventAggregator,
    });
};

function showModalContent(context, templateSelector, title) {
    context.$content.html($(templateSelector).text());

    if (title) {
        context.$header.text(title);
    }
}

export default SalesforceCouponModalView;
