import ExceptionFormatter from "js/ajax-exception-formatter";

function ExtoleError(response) {
    response = response || {};
    const responseJSON = response.responseJSON || response;
    const code = responseJSON && responseJSON.code;
    const message = responseJSON && responseJSON.message;
    const httpStatusCode = responseJSON && responseJSON.http_status_code;

    this.getCode = function () {
        return code;
    };

    this.getMessage = function () {
        return message;
    };

    this.getDefaultMessage = function () {
        return ExceptionFormatter.getMessage(response);
    };

    this.getHttpStatusCode = function () {
        return httpStatusCode;
    };
}

export default ExtoleError;
