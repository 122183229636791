import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";

const RadioGroupView = Backbone.View.extend({
    events: {
        "click .js-switch-option": "switchOption",
    },

    initialize() {
        this.$options = this.$(".js-switch-option");
        this.toggleFieldsState(this.$options.filter(":checked"));
    },

    switchOption(event) {
        const $currentOption = $(event.currentTarget);
        const otherOptionsChecked = !$currentOption.is(":checked");
        this.$options.not($currentOption).prop("checked", otherOptionsChecked);
        const $checkedOption = this.$options.filter(":checked");

        this.model.set($checkedOption.data("name"), $checkedOption.val());
        this.toggleFieldsState($checkedOption);

        setTimeout(() => {
            $checkedOption
                .parent()
                .siblings(":first")
                .find("input,textarea")
                .first()
                .focus();
        });
    },

    toggleFieldsState($checkedOption) {
        getInputFields($checkedOption).each(disableField.bind(null, false));
        getInputFields(this.$options.not($checkedOption)).each(
            disableField.bind(null, true)
        );
    },
});

function getInputFields($selector) {
    return $selector.parent().siblings().find("input,select,textarea");
}

function disableField(disabled, index, field) {
    $(field).prop("disabled", disabled);
}

RadioGroupView.create = function ($el, model) {
    return new RadioGroupView({
        el: $el,
        model,
    }).render();
};

export default RadioGroupView;
