import RewardSupplierManualCouponManagerView from "src/account-rewards/workflow/RewardSupplierManualCouponManagerView";
import RewardSupplierPaypalPayoutManagerView from "src/account-rewards/workflow/RewardSupplierPaypalPayoutManagerView";
import CustomRewardModalView from "src/shared/custom-reward/CustomRewardModalView";
import SalesforceCouponModalView from "src/shared/salesforce-coupon/SalesforceCouponModalView";

const REWARD_TYPES = {
    CUSTOM_REWARD: {
        name: "Custom Rewards",
        typeLink: "/account-rewards/custom-rewards",
        link: "/account-rewards/custom-rewards/view#{id}",
        iconClass: "fas fa-wallet",
        EditView: CustomRewardModalView,
        CreateView: CustomRewardModalView,
    },
    TANGO_V2: {
        name: "Gift Cards",
        typeLink: "/account-rewards/gift-cards",
        link: "/account-rewards/gift-cards/view#{id}",
        iconClass: "fas fa-gift",
    },
    MANUAL_COUPON: {
        name: "Coupons",
        typeLink: "/account-rewards/coupons",
        link: "/account-rewards/coupons/view#{id}",
        iconClass: "fas fa-money-check",
        EditView: RewardSupplierManualCouponManagerView,
        CreateView: RewardSupplierManualCouponManagerView,
    },
    SALESFORCE_COUPON: {
        name: "SFCC Coupons",
        typeLink: "/account-rewards/SFCC",
        link: "/account-rewards/SFCC/view#{id}",
        iconClass: "fas fa-cloud",
        EditView: SalesforceCouponModalView,
        CreateView: SalesforceCouponModalView,
    },
    PAYPAL_PAYOUTS: {
        name: "Paypal Payouts",
        typeLink: "/account-rewards/paypal-payouts",
        link: "/account-rewards/paypal-payouts/view#{id}",
        iconClass: "fab fa-paypal",
        EditView: RewardSupplierPaypalPayoutManagerView,
        CreateView: RewardSupplierPaypalPayoutManagerView,
    },
};

function lookup(type) {
    return REWARD_TYPES[type] || {};
}

export default {
    lookup,
    getDisplayName(type) {
        return lookup(type).name || `/account-rewards/${type}/view#{id}`;
    },
    getTypePageLink(type) {
        return lookup(type).typeLink;
    },
    getDetailPageLink(type, rewardSupplierId) {
        const detailLinkTemplate = lookup(type).link;
        if (detailLinkTemplate) {
            return detailLinkTemplate.replace("{id}", rewardSupplierId);
        }
        return undefined;
    },
    getIconClass(type) {
        return lookup(type).iconClass;
    },
    getEditViewClass(type) {
        return lookup(type).EditView;
    },
    getCreateViewClass(type) {
        return lookup(type).CreateView;
    },
};
