import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Strings from "src/shared/Strings";
import InnerUploadView from "src/shared/upload-file-modal/InnerUploadView";
import template from "src/account-rewards/workflow/reward-supplier-manual-coupon-upload-template.html?raw";

const BYTES_IN_KILOBYTE = 1024;
const EVENTS = {
    FILE_UPLOADED: "FILE_UPLOADED",
};

const RewardSupplierManualUploadCouponView = InnerUploadView.extend({
    template: _.template(template),

    initialize(attributes) {
        this.message = attributes.message;
    },

    changeFileName() {
        const file = this.$fileField[0].files[0] || this.file;
        if (!file) {
            return;
        }

        const size =
            file.size < BYTES_IN_KILOBYTE
                ? `${file.size} bytes`
                : `${Math.round(file.size / BYTES_IN_KILOBYTE)}kb`;

        this.$fileName.html(`${file.name} (${size})`);
        this.file = file;
        this.trigger(EVENTS.FILE_UPLOADED, this.file);
    },
});

RewardSupplierManualUploadCouponView.create = function (message, model) {
    return new RewardSupplierManualUploadCouponView({
        message,
        model,
    }).render();
};

export default RewardSupplierManualUploadCouponView;
