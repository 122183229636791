import Backbone from "backbone";
import Uri from "jsuri";

const CampaignModel = Backbone.Model.extend({
    idAttribute: "campaign_id",

    urlRoot: "/api/v2/campaigns",
});

const CampaignCollection = Backbone.Collection.extend({
    model: CampaignModel,

    initialize(attributes, options) {
        this.rewardSupplierId = options.rewardSupplierId;
    },

    url() {
        const uri = new Uri("/api/v2/campaigns");
        uri.addQueryParam("reward_supplier_id", this.rewardSupplierId);

        return uri.toString();
    },
});

export default CampaignCollection;
