import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import RewardSupplierArchiveModalView from "src/shared/modals/RewardSupplierArchiveModalView";
import RewardEvents from "src/shared/rewards/RewardEvents";
import NotificationEllipsisView from "src/shared/notification/NotificationEllipsisView";
import stickyAlertMessageService from "src/shared/alert-messages/sticky-alert-message-service";
import RewardSupplierFormatter from "src/shared/RewardSupplierFormatter";
import NumericInputMask from "src/shared/NumericInputMask";
import FormView from "src/shared/FormView";
import Strings from "src/shared/Strings";
import RadioGroupView from "src/shared/RadioGroupView";
import ToggleSectionView from "src/shared/ToggleSectionView";
import TooltipView from "src/shared/tooltip/TooltipView";
import tooltipConfigurations from "src/shared/paypal-payout/tooltip-configurations.json";
import pageTemplate from "src/account-rewards/workflow/reward-supplier-paypal-payout-manager-view-template.html?raw";

const RewardSupplierPaypalPayoutManagerView = Backbone.View.extend(
    {
        template: _.template(pageTemplate),

        events: {
            "click #js-back": "back",
            "click #js-delete": "showConfirmDelete",
        },

        attributes: {
            class: "action-view__positioner",
        },

        initialize(parameters) {
            this.mainEventAggregator = parameters.mainEventAggregator;
            this.model.once("sync", this.onSave.bind(this));
            this.render();
        },

        render() {
            const paypalPayout = this.model.toJSON();
            let title;
            if (paypalPayout.id) {
                title = `Edit ${Strings.capitalize(paypalPayout.name)}`;
                this.isNew = false;
            } else {
                title = paypalPayout.isDuplicate
                    ? `Duplicate ${Strings.capitalize(paypalPayout.name)}`
                    : "New Paypal Payout";
                this.isNew = true;
            }

            this.$el.html(
                this.template(
                    _.extend(paypalPayout, {
                        title,
                        isNew: this.isNew,
                        valueTypes:
                            RewardSupplierFormatter.getPossibleValueTypes(),
                    })
                )
            );
            this.$deleteButton = this.$("#js-delete");
            this.$errorMessage = this.$(".js-error-message");

            new FormView.Builder()
                .withRootElement(this.$("form"))
                .withModel(this.model)
                .withStickyAlertMessageService(stickyAlertMessageService)
                .render();

            TooltipView.renderAll(this.$el, tooltipConfigurations);
            NotificationEllipsisView.renderAll(this.$el);
            NumericInputMask.renderAll(this.$(".js-masked-input"));
            RadioGroupView.create(this.$("#js-value-wrapper"), this.model);
            ToggleSectionView.create(this.$("#js-advanced-section-wrapper"));

            return this;
        },

        onSave() {
            this.mainEventAggregator.trigger(
                RewardEvents.REWARD_SAVED,
                this.model
            );
        },

        showConfirmDelete() {
            RewardSupplierArchiveModalView.show(
                this.model,
                this.mainEventAggregator
            );
        },

        back() {
            this.mainEventAggregator.trigger(RewardEvents.BACK);
        },
    },
    {
        create(model, mainEventAggregator) {
            return new RewardSupplierPaypalPayoutManagerView({
                model,
                mainEventAggregator,
            });
        },
    }
);

export default RewardSupplierPaypalPayoutManagerView;
