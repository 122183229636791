import StickyAlertMessageView from "src/shared/alert-messages/StickyAlertMessageView";
import $ from "jquery";

const MESSAGE_TYPES = {
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error",
};

function StickyAlertMessageBuilder(rootElement) {
    let _message;
    let _type = MESSAGE_TYPES.ERROR;

    this.withMessage = function (message) {
        _message = message;
        return this;
    };

    this.withType = function (type) {
        _type = type;
        return this;
    };

    this.render = function () {
        const stickyAlertMessageView = new StickyAlertMessageView({
            message: _message,
            type: _type,
        }).render();

        rootElement.append(stickyAlertMessageView.$el);

        return stickyAlertMessageView;
    };
}

function StickyAlertMessageService() {
    this.buildAlertMessage = function () {
        const rootElement = $(
            "<div class='js-alert-message-wrapper alert-message-wrapper'></div>"
        );
        $(document.body).append(rootElement);
        return new StickyAlertMessageBuilder(rootElement);
    };

    this.getTypes = function () {
        return MESSAGE_TYPES;
    };

    this.getEvents = function () {
        return StickyAlertMessageView.EVENTS;
    };
}

export default new StickyAlertMessageService();
