import Backbone from "backbone";

const REWARD_SUPPLIER_STATES = {
    EARNED: "EARNED",
    FAILED: "FAILED",
    FULFILLED: "FULFILLED",
    SENT: "SENT",
};

const RewardModel = Backbone.Model.extend(
    {
        idAttribute: "reward_id",

        urlRoot: "/api/v2/rewards",

        getHistory() {
            return Backbone.ajax({
                url: `${this.url()}/history`,
                dataType: "json",
            });
        },
    },
    {
        STATES: REWARD_SUPPLIER_STATES,
    }
);

export default RewardModel;
