import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Session from "Session";
import moment from "moment";
import dateTimeService from "src/shared/date-time-service";
import calendarTemplate from "src/shared/calendar/calendar-template.html?raw";
import Foundation from "foundation";
import "jquery.daterangepicker";
import "moment-timezone";

const EVENTS = {
    CHANGED: "CHANGED",
};

function addTimeToDate(model, field, hour, minute) {
    const dateTime = moment(model.get(field));
    if (hour) {
        dateTime.set("hour", hour);
    }

    if (minute) {
        dateTime.set("minute", minute);
    }

    return dateTime.toDate();
}

const CalendarView = Backbone.View.extend(
    {
        template: _.template(calendarTemplate),

        attributes: {
            class: "action-view__positioner",
        },

        events: {
            "datepicker-apply": "closeDropdown",
            "datepicker-clear": "clearDate",
            "datepicker-change": "updateCustomDate",
            "datepicker-change-time": "updateCustomDateTime",
            "click .js-calendar-button": "setCalendarDate",
        },

        initialize(options) {
            this.name = options.name;
            this.value = options.value;
            this.align = options.align;
            this.shouldSupportTime = options.shouldSupportTime;
            this.shouldSupportConversion = options.shouldSupportConversion;
            this.shouldSupportOmissible = options.shouldSupportOmissible;
            this.timezone = Session.getInstance().getClient().getTimezone();
        },

        render() {
            this.$calendarWrapper = this.$(".js-calendar-wrapper");
            this.$calendarWrapper.html(
                this.template({
                    id: _.uniqueId(),
                    name: this.name,
                    align: this.align,
                    shouldSupportTime: this.shouldSupportTime,
                    shouldSupportOmissible: this.shouldSupportOmissible,
                })
            );
            this.$calendarWrapper.addClass(
                `calendar-wrapper calendar-wrapper--single ${this.align}`
            );
            this.$calendarButton = this.$(".js-calendar-button");
            this.$calendarContainer = this.$(".js-calendar-dropdown");
            this.$displayField = this.$(".js-display-value");
            this.$secondDisplayField = this.$(".js-display-value-2");
            this.$valueField = this.$(`[name='${this.name}']`);
            this.$displayField.on("change", this.setCalendarDate.bind(this));

            if (this.shouldSupportTime) {
                this.$calendarContainer.addClass("calendar--with-time");
            }

            if (dateTimeService.isDateValid(this.value)) {
                this.setDate(this.value, this.shouldSupportConversion);
            } else if (_.isString(this.value) && !_.contains(this.value, "/")) {
                this.$valueField.val(this.value);
            }

            return this;
        },

        setCalendarDate(event) {
            const shouldOmiss =
                this.shouldSupportOmissible && _.isEmpty($(event.target).val());
            this.initializeDatePicker();
            const date = this.getDate();
            this.$calendarContainer.data("dateRangePicker").setDateRange(date);
            this.setDate(date, null, shouldOmiss);
        },

        initializeDatePicker() {
            const datePickerOptions = {
                singleDate: true,
                showShortcuts: false,
                inline: true,
                autoClose: false,
                alwaysOpen: true,
                timezone: this.timezone,
                shouldInitializeTime: this.shouldSupportTime,
                container: this.$calendarContainer,
                time: {
                    enabled: this.shouldSupportTime,
                },
                clearDateValue: true,
            };

            if (!this.isInitialized) {
                this.isInitialized = true;
                this.$calendarContainer.dateRangePicker(datePickerOptions);

                $(this.$calendarContainer).foundation();
            }
        },

        updateCustomDate(event, dateRange) {
            if (!dateRange) {
                return;
            }

            this.setDate(dateRange.date1);
        },

        updateCustomDateTime(event, timeRange) {
            if (!timeRange) {
                return;
            }

            const date = addTimeToDate(
                this.model,
                this.name,
                timeRange.hour,
                timeRange.min
            );
            this.setDate(date);
        },

        getDate() {
            const date = this.model.get(this.name) || this.value;
            if (date && moment(date).isValid()) {
                return moment(date).toDate();
            }
            const dateInTimezone = moment().tz(this.timezone).startOf("day");
            return moment(
                dateTimeService.formatToQueryDateTime(dateInTimezone)
            ).toDate();
        },

        updateDateModel(formattedDate, formattedDateString) {
            this.model.set(this.name, formattedDate);
            this.$valueField.val(formattedDate);
            this.$displayField
                .val(formattedDateString)
                .text(formattedDateString);
            this.trigger(EVENTS.CHANGED);
        },

        setDate(date, withConversion, shouldOmiss) {
            let formattedDate;
            let formattedDateString;
            if (this.shouldSupportTime) {
                formattedDate = dateTimeService.formatToQueryDateTime(
                    date,
                    withConversion
                );
                formattedDateString = dateTimeService.formatToDateTime(
                    date,
                    withConversion
                );
            } else {
                formattedDate = dateTimeService.formatToQueryDateTime(
                    date,
                    withConversion
                );
                formattedDateString = dateTimeService.formatToDate(
                    date,
                    withConversion
                );
            }
            if (shouldOmiss) {
                formattedDate = null;
                formattedDateString = null;
            }
            this.updateDateModel(formattedDate, formattedDateString);
            if (!this.shouldSupportTime) {
                this.closeDropdown();
            }
        },

        closeDropdown() {
            Foundation.libs.dropdown.close(this.$calendarContainer);
        },

        clearDate() {
            this.updateDateModel(null, null);
        },

        show() {
            const self = this;
            setTimeout(() => {
                self.$calendarButton.click();
            }, 0);
        },

        hide() {
            this.closeDropdown();
        },
    },
    {
        EVENTS,
    }
);

CalendarView.Builder = function ($el) {
    const _$el = $el;
    let _name;
    let _value;
    let _model;
    let _align = "right";
    let _shouldSupportTime;
    let _shouldSupportConversion;
    let _shouldSupportOmissible;

    this.withName = function (name) {
        _name = name;
        return this;
    };

    this.withValue = function (value) {
        _value = value;
        return this;
    };

    this.withModel = function (model) {
        _model = model;
        return this;
    };

    this.withAlign = function (align) {
        _align = align;
        return this;
    };

    this.withSupportTime = function (shouldSupportTime) {
        _shouldSupportTime = shouldSupportTime;
        return this;
    };

    this.withConversion = function (shouldSupportConversion) {
        _shouldSupportConversion = shouldSupportConversion;
        return this;
    };

    this.withSupportOmissible = function (shouldSupportOmissible) {
        _shouldSupportOmissible = shouldSupportOmissible;
        return this;
    };

    this.build = function () {
        return new CalendarView({
            el: _$el,
            name: _name,
            value: _value,
            model: _model,
            align: _align,
            shouldSupportTime: _shouldSupportTime,
            shouldSupportConversion: _shouldSupportConversion,
            shouldSupportOmissible: _shouldSupportOmissible,
        }).render();
    };
};

CalendarView.create = function ($el) {
    return new CalendarView.Builder($el);
};

export default CalendarView;
