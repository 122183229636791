function getMessage(response) {
    const statusCode = response && response.status;
    const responseJSON = (response && response.responseJSON) || {};

    switch (statusCode) {
        case 0:
            return "Sorry we are having problems connecting to our api - do you have a firewall or adblocker enabled?";

        case 400:
            return responseJSON.message || "The provided request was invalid.";

        case 401:
            return responseJSON.message || "Unauthorized, please log in.";

        case 403:
            return (
                responseJSON.message ||
                "Forbidden, you are not allowed to access this resource."
            );

        case 500:
            return "Hmm, something unexpected happened. Please try again later or contact support@extole.com";

        case 503:
            return "Service unavailable, please try again later.";

        default:
            return "Hmm, something unexpected happened. Please try again later or contact support@extole.com";
    }
}

export default {
    getMessage,
};
