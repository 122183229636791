import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";

const SHOW_MORE = "Show More";
const SHOW_LESS = "Show Less";
const HIDE_REGEX = /^Hide/;
const HIDE = "Hide";

const ToggleSectionView = Backbone.View.extend({
    initialize(options) {
        this.visibleByDefault = options.visibleByDefault;
        this.additionalLinkSelector = options.additionalLinkSelector || "";
    },

    render() {
        this.$toggleLink = this.$(
            `.js-toggle-link${this.additionalLinkSelector}`
        );
        this.$toggledSection = this.$(".js-toggled-section");

        this.$toggleLink.on("click", this.toggle.bind(this));

        if (this.visibleByDefault) {
            this.hiddenContentLinkText = this.$toggleLink.text();
            this.visibleContentLinkText =
                this.hiddenContentLinkText === SHOW_LESS
                    ? SHOW_MORE
                    : this.hiddenContentLinkText.replace(HIDE_REGEX, "");
        } else {
            this.$toggledSection.hide();
            this.visibleContentLinkText = this.$toggleLink.text();
            this.hiddenContentLinkText =
                this.visibleContentLinkText === SHOW_MORE
                    ? SHOW_LESS
                    : `${HIDE} ${this.visibleContentLinkText}`;
        }
    },

    toggle() {
        if (this.$toggledSection.is(":visible")) {
            this.$toggleLink.text(this.visibleContentLinkText);
            this.$toggledSection.hide();
        } else {
            this.$toggleLink.text(this.hiddenContentLinkText);
            this.$toggledSection.show();
        }
    },
});

ToggleSectionView.createAll = function (
    $elements,
    visibleByDefault,
    additionalLinkSelector
) {
    $elements.each(function () {
        new ToggleSectionView({
            el: $(this),
            visibleByDefault,
            additionalLinkSelector,
        }).render();
    });
};

ToggleSectionView.create = function (
    $el,
    visibleByDefault,
    additionalLinkSelector
) {
    return new ToggleSectionView({
        el: $el,
        visibleByDefault,
        additionalLinkSelector,
    }).render();
};

export default ToggleSectionView;
