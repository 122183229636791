import Backbone from "backbone";
import Uri from "jsuri";
import _ from "underscore";
import RewardStateSummaryModel from "src/account-rewards/RewardStateSummaryModel";

const URL_ROOT = "/api/v2/rewards/state_summary";

const RewardStateSummaryCollection = Backbone.Collection.extend({
    model: RewardStateSummaryModel,

    initialize(attributes, options) {
        this.stateModel = options && options.stateModel;
    },

    url() {
        const uri = new Uri(URL_ROOT);
        if (this.stateModel) {
            const filter = this.stateModel.toJSON();
            _.each(_.pairs(filter), (pair) => {
                uri.addQueryParam(pair[0], pair[1]);
            });
        }

        return uri.toString();
    },

    parse(models) {
        const initialData = {
            earned: 0,
            fulfilled: 0,
            sent: 0,
            canceled: 0,
            redeemed: 0,
            failed: 0,
            revoked: 0,
        };

        const groupedData = models.reduce((accumulator, model) => {
            _.each(_.keys(initialData), (key) => {
                accumulator[key] += model[key];
            });

            return accumulator;
        }, initialData);

        models.groupedData = groupedData;

        return models;
    },
});

export default RewardStateSummaryCollection;
