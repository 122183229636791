import _ from "underscore";
const UPPER_CASE_WORDS = [
    "id",
    "url",
    "usd",
    "eur",
    "sfcc",
    "jpy",
    "gbp",
    "cny",
    "cad",
    "aud",
    "brl",
    "inr",
    "nzd",
    "mxn",
    "krw",
    "twd",
    "ssh",
    "pgp",
    "jwt",
    "sms",
    "http",
    "bhn",
];
const REGULAR_WORDS_MAP = {
    sessionm: "SessionM",
};
const REGULAR_TEXT_REGEX = /[\s/!.,:;?+\-_()]+/;
function capitalizeWord(word) {
    if (word.length === 0) {
        return "";
    }
    if (REGULAR_WORDS_MAP[word]) {
        return REGULAR_WORDS_MAP[word];
    }
    if (_.contains(UPPER_CASE_WORDS, word)) {
        return word.toUpperCase();
    }
    return word[0].toUpperCase() + word.slice(1);
}
class Strings {
    constructor(line) {
        this.line = line ? line.toString() : "";
    }
    toLowerCase() {
        var _a;
        this.line = (_a = this.line) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        return this;
    }
    replaceAllAndCapitalize(separator, joinWith = _.isString(separator) ? separator : "") {
        if (this.line) {
            this.line = this.line
                .split(separator)
                .map(capitalizeWord)
                .join(joinWith);
        }
        return this;
    }
    replaceAll(separator, joinWith = _.isString(separator) ? separator : "") {
        if (this.line) {
            this.line = this.line.split(separator).join(joinWith);
        }
        return this;
    }
    decodeHtmlEntity() {
        if (this.line) {
            this.line = this.line.replace(/&#(\d+);/g, (match, decimalNumber) => String.fromCharCode(decimalNumber));
        }
        return this;
    }
    toString() {
        return this.line;
    }
}
const capitalizeCamelCase = (value) => {
    if (value === undefined || value === null) {
        return String(value);
    }
    if (typeof value !== "string") {
        value = String(value);
    }
    if (!/[A-Z]/.test(value)) {
        value = value.replace(/_/g, " "); // Replace underscores with spaces
        value = value.replace(/-/g, " "); // Replace dashes with spaces
        return value
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    }
    value = value.replace(/_/g, ""); // Remove underscores for camel case
    value = value.replace(/-/g, ""); // Remove dashes for camel case
    const words = [];
    let currentWord = "";
    for (let i = 0; i < value.length; i++) {
        const char = value[i];
        if (char === char.toUpperCase()) {
            if (currentWord.length > 0) {
                words.push(currentWord);
                currentWord = "";
            }
        }
        currentWord += char;
    }
    if (currentWord.length > 0) {
        words.push(currentWord);
    }
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    const result = capitalizedWords.join(" ");
    return result;
};
export default {
    capitalize(line) {
        return new Strings(line !== null && line !== void 0 ? line : "")
            .toLowerCase()
            .replaceAllAndCapitalize(/[\s_-]+/, " ")
            .replaceAllAndCapitalize("/")
            .replaceAllAndCapitalize("(")
            .replaceAllAndCapitalize(")")
            .toString();
    },
    capitalizeCamelCase,
    capitalizeFirstLetter(line) {
        return new Strings(line).replaceAllAndCapitalize(".").toString();
    },
    decodeHtmlEntity(line) {
        return new Strings(line).decodeHtmlEntity().toString();
    },
    encodeLabel(line) {
        return new Strings(line)
            .toLowerCase()
            .replaceAll(REGULAR_TEXT_REGEX, "-")
            .toString();
    },
    encodeSnakeCase(line) {
        return new Strings(line)
            .toLowerCase()
            .replaceAll(REGULAR_TEXT_REGEX, "_")
            .toString();
    },
    convertToClassName(line) {
        return new Strings(line).toLowerCase().replaceAll("_", "-").toString();
    },
    replaceAll(line, separator, joinWith) {
        return new Strings(line).replaceAll(separator, joinWith).toString();
    },
    decode(line) {
        if (_.isEmpty(line)) {
            return line;
        }
        try {
            if (line.includes("% ")) {
                line = line.replace("% ", "%25%20");
            }
            return decodeURIComponent(line);
        }
        catch (error) {
            console.error(error);
            return line;
        }
    },
    getInitials(line) {
        if (!line) {
            return "";
        }
        return line
            .toString()
            .split(" ")
            .map((word) => {
            if (word && word[0]) {
                return word[0].toUpperCase();
            }
            return "";
        })
            .join("");
    },
};
